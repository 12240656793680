import "./assets/css/app.css";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { setWithExpiry, setWithoutExpiry } from "./helper/storageUtils";
import { fetchVersion, getTimeUntilRefresh } from "./api/apiClient";
import posthog from "posthog-js";
import { ToastContainer } from "react-toastify";
import { createClient, Session } from "@supabase/supabase-js";
import { gsap } from "gsap";
import LoadingScreen from "./components/miscs/LoadingScreen";
import Profile from "./components/user/Profile";
import BottomMenuBar from "./components/template/BottomMenuBar";
import Chat from "./components/chat/Chat";

import PaymentSuccessful from "./components/payments/PaymentSuccessful";
import PaymentFailed from "./components/payments/PaymentFailed";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import StyledWaitlistComponent from "./components/user/WaitlistComponent";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import {
  AuthenticatedUser,
  convertAuthenticatedUserToUser,
  fetchUser,
  convertUserToAuthenticatedUser
} from "./helper/authHelper";
import VoiceChat from "./components/chat/VoiceChat";
import VoiceChatVapi from "./components/chat/VoiceChatVapi";
import ChatExport from "./components/chat/ChatExport";
import NewChat from "./components/chat/NewChat";
import CameraCaptureSearch from "./components/miscs/CameraCaptureSearch";
import ErrorPage from "./components/miscs/ErrorPage";
import UnsubscribePage from "./components/miscs/UnsubscribePage";
import CheckoutModal from "./components/payments/CheckoutModal";
import { ModalContext } from "./components/modals/ModalContext";
import HybridSystemProvider from "./components/user/HybridSystem";
import { useBackgroundSync } from "./hooks/BackgroundSyncHook";
import Search from "./components/search/Search";
import ChatRouter from "./components/chat/ChatRouter";

// const TemporaryHome = lazy(() => import("./components/miscs/TemporaryHome"));
// const PaymentSuccessful = lazy(() => import("./components/payments/PaymentSuccessful"));
// const PaymentFailed = lazy(() => import("./components/payments/PaymentFailed"));
// const StyledWaitlistComponent = lazy(() => import("./components/user/WaitlistComponent"));
// const VoiceChat = lazy(() => import("./components/chat/VoiceChat"));
// const VoiceChatVapi = lazy(() => import("./components/chat/VoiceChatVapi"));
// const ChatExport = lazy(() => import("./components/chat/ChatExport"));
// const NewChat = lazy(() => import("./components/chat/NewChat"));
// const CameraCaptureSearch = lazy(() => import("./components/miscs/CameraCaptureSearch"));
// const ErrorPage = lazy(() => import("./components/miscs/ErrorPage"));
// const UnsubscribePage = lazy(() => import("./components/miscs/UnsubscribePage"));
// const ChatRouter = lazy(() => import("./components/chat/ChatRouter"));
// // const Search = lazy(() => import("./components/search/Search"));
// const Profile = lazy(() => import("./components/user/Profile"));
// const Chat = lazy(() => import("./components/chat/Chat"));

const supabase = createClient("https://vcsqhuxpigrhqgausqit.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZjc3FodXhwaWdyaHFnYXVzcWl0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMTgzMjEsImV4cCI6MjAzMDU5NDMyMX0.hI9bP4VAttWzaHC_n-pCr-59DNHky-jn-46l8AK8_hU");

function App() {
  const location = useLocation();
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [userUUID, setUserUUID] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AuthenticatedUser | undefined>(undefined);
  const [session, setSession] = useState<Session | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setTimeout(() => {
        log.info("AUTH SESSION");
        setSession(session);
        posthog.identify(session?.user.email);
        if (session && session.user && session.user.email) {
          setWithoutExpiry("User_identifier", session.user.email);
          setWithoutExpiry("logged_in", true);
        } else if (userUUID != null) {
          setWithoutExpiry("User_identifier", "unlogged-" + userUUID);
        }
        if (isFirstRender.current) {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
          isFirstRender.current = false;
        } else {
          setLoading(false);
        }
        fetchUser(setLoading, convertUserToAuthenticatedUser, setUser, setUser);
      }, 1500);
    });

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setTimeout(() => {
        log.info("SETTING SESSION");
        setSession(session);
        if (!isFirstRender.current) {
          setLoading(false);
        }
        fetchUser(setLoading, convertUserToAuthenticatedUser, setUser, setUser);
      }, 1000);
    });


    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    fetchVersion();
    fetchAndStoreTimestamp();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const fetchAndStoreTimestamp = async () => {
    try {
      const refreshData = await getTimeUntilRefresh();
      const futureTimestamp = refreshData.time_until_refresh * 1000;
      setWithExpiry("refreshTimestamp", futureTimestamp, 60000);
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/" && !isFirstRender.current) {
      const pageTransition = gsap.timeline({
        defaults: { duration: 0.3, ease: "power2.inOut" }
      });
      pageTransition.fromTo(
        ".main-content",
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0 }
      );
    }
  }, [location]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
  }, []);

  const handleItemClick = (e: React.MouseEvent) => {
    const target = e.currentTarget as HTMLElement;
    gsap.to(target, { backgroundColor: "#e0e0e0", duration: 0.2, yoyo: true, repeat: 1, ease: "none" });
  };

  // This effect has been removed as it was preventing multi-touch gestures
  // and potentially interfering with normal scrolling behavior.
  // For better UX and responsiveness, we should allow default touch behaviors.

  // This effect sets up accessibility attributes for draggable elements
  useEffect(() => {
    const draggableElements = document.querySelectorAll("[draggable]");
    draggableElements.forEach(el => {
      el.setAttribute("aria-grabbed", "false");
      el.addEventListener("dragstart", () => el.setAttribute("aria-grabbed", "true"));
      el.addEventListener("dragend", () => el.setAttribute("aria-grabbed", "false"));
    });
  }, []);

  const svgBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/background.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100%"
  };

  if (loading) {
    return <LoadingScreen />;
  }

  // const feedback = feedbackIntegration({
  //   // Disable injecting the default widget
  //   autoInject: true,
  // });
  //
  // const widget = feedback.createWidget();


  return (
    <>
      {/*<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>*/}
      <ModalContext.Provider value={{ openModal, user }}>
        <HybridSystemProvider userEmail={user?.email}>
          <ToastContainer />
          {isModalOpen && user && (
            <CheckoutModal
              isOpen={isModalOpen}
              onClose={closeModal}
              user={convertAuthenticatedUserToUser(user)}
              displaySource={"account"}
            />
          )}
          <div className="App" style={svgBackground}>
            <Suspense fallback={<LoadingScreen />}>
              <div className="main-content">
                <Routes>
                  <Route path="/" element={<Navigate to="/search" replace />} />
                  <Route path="/profile"
                         element={<Profile supabase={supabase} session={session} setLoggedInUser={setUser} />} />
                  <Route
                    path="/search"
                    element={<ProtectedRoute component={Search} supabase={supabase} session={session} user={user} />}
                  />
                  <Route
                    path="/chat"
                    element={
                      <ProtectedRoute
                        component={Chat}
                        supabase={supabase}
                        session={session}
                        user={user}
                        chat_type="popular"
                      />
                    }
                  />
                  <Route
                    path="/chat/:token"
                    element={
                      <ProtectedRoute
                        component={ChatRouter}
                        supabase={supabase}
                        session={session}
                        user={user}
                      />
                    }
                  />
                  <Route path="/payment-success" element={<PaymentSuccessful />} />
                  <Route path="/waitlist" element={<StyledWaitlistComponent supabase={supabase} />} />
                  <Route path="/payment-failure" element={<PaymentFailed />} />
                  <Route path="/voice-chat" element={<VoiceChat />} />
                  <Route path="/scene-camera" element={<CameraCaptureSearch />} />
                  <Route path="/voice-chat-vapi" element={<VoiceChatVapi />} />
                  <Route path="/new_chat" element={<NewChat />} />
                  <Route path="/api/email/unsubscribe/:token" element={<UnsubscribePage />} />
                  <Route
                    path="/export"
                    element={<ChatExport user={user} />}
                  />
                  <Route
                    path="/export/:exportId"
                    element={<ChatExport />}
                  />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </div>
            </Suspense>
            {location.pathname !== "/waitlist" && <BottomMenuBar />}
          </div>
        </HybridSystemProvider>
      </ModalContext.Provider>
      {/*</Sentry.ErrorBoundary>*/
      }
    </>
  )
    ;
}

export default App;