import React, { useEffect, useState } from "react";
import * as authService from "../../api/authClient";
import "../../css/UserDetailsModal.css";
import { toast } from "react-toastify";
import { UserDetailsType } from "../../types/UserDetailsType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import log from "loglevel";

interface UserDetailsManagerProps {
  initialDetails?: UserDetailsType;
  setInitialDetails?: (details: UserDetailsType) => void;
}

const UserDetailsManager: React.FC<UserDetailsManagerProps> = (props: UserDetailsManagerProps) => {
  const [details, setDetails] = useState<UserDetailsType>(props.initialDetails || {
    name: "",
    age: 0,
    profession: "",
    hobby: "",
    gender: "",
    location: ""
  });

  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isComplete, setIsComplete] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(!!props.initialDetails);
  const [showNavigation, setShowNavigation] = useState<boolean>(!props.initialDetails);

  useEffect(() => {
    if (!props.initialDetails) {
      checkUserDetails();
    }
  }, []);

  const fetchUserDetails = async () => {
    try {
      const data = await authService.getUserDetails();
      setDetails(data);
      checkCompleteness(data);
    } catch (error) {
      setError("Failed to fetch user details. Please try again.");
    }
  };

  const checkCompleteness = (data: UserDetailsType) => {
    const complete = Boolean(data.name && data.age && data.profession && data.hobby && data.gender && data.location);
    setIsComplete(complete);
    setIsEditing(!complete);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate the required 'name' field
    if (!details.name.trim()) {
      setError("Name is required.");
      return;
    }

    try {
      const detailsToSubmit = {
        ...details
      };

      if (!props.initialDetails) {
        await authService.updateUserDetails(detailsToSubmit);
      } else {
        props.setInitialDetails && props.setInitialDetails(detailsToSubmit);
      }

      setIsEditing(false);
      setTimeout(() => {
        toast.success("Details successfully updated!");
      }, 300);
      setShowModal(false);
    } catch (error) {
      setError("Failed to update user details. Please try again.");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const checkUserDetails = async () => {
    try {
      const data = await authService.getUserDetails();
      const isComplete = Boolean(data.name && data.age !== null && data.profession && data.hobby);
      setIsComplete(isComplete);
      setDetails(data);
    } catch (error) {
      log.error("Error checking user details:", error);
    }
  };

  const orderedFields = ["name", "age", "profession", "hobby", "gender", "location"];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showNavigation && (
        <>
          {!isComplete ? (
            <button
              onClick={() => setShowModal(true)}
              style={{
                borderRadius: "20px",
                padding: "10px 20px",
                border: "1px solid #000",
                backgroundColor: "white",
                color: "black"
              }}
            >
              Complete Profile
            </button>
          ) : (
            <button
              onClick={() => setShowModal(true)}
              style={{
                borderRadius: "20px",
                padding: "10px 20px",
                border: "1px solid #000",
                backgroundColor: "white",
                color: "black"
              }}
            >
              Edit Profile
            </button>
          )}
        </>
      )}
      {showModal && (
        <div className="ad-consent-modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button onClick={handleCloseModal} className="close-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="modal-header">
              <h2>{props.initialDetails ? "Your Temporary Details" : "Your Details"}</h2>
            </div>
            <div className="modal-body">
              {error && (
                <p style={{
                  color: "red",
                  fontWeight: "bold",
                  marginTop: "10px",
                  textAlign: "center",
                  fontSize: "14px"
                }}>
                  {error}
                </p>
              )}

              <form onSubmit={handleSubmit}>
                {orderedFields.map((key) => (
                  <div className="form-group" key={key}>
                    <div className="input-container">
                      <span className="input-label">{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                      <input
                        type={key === "age" ? "number" : "text"}
                        className="input-field"
                        name={key}
                        value={details[key as keyof UserDetailsType]}
                        onChange={handleInputChange}
                        placeholder={key === "name" ? "Required" : ""}
                      />
                    </div>
                  </div>
                ))}
                <button type="submit" className="accept-btn">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailsManager;