import React, { useState } from "react";
import { createCheckoutSession } from "../../api/apiClient";
import { AuthenticatedUser } from "../../helper/authHelper"; // Update this import path if necessary
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { User } from "../../types/UserType";
import { loadStripe } from "@stripe/stripe-js";
import posthog from "posthog-js";

type CheckoutButtonProps = {
  user: User;
  className?: string;
  children?: React.ReactNode;
  plan: "monthly" | "annual";
  onButtonClick: () => void;
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY|| "");


const CheckoutButton: React.FC<CheckoutButtonProps> = ({ user, className, children, plan, onButtonClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    posthog.capture("user clicked upgrade button");
    const rootUrl = window.location.origin;
    const session = await createCheckoutSession(rootUrl, user.email, plan);
    const stripe = await stripePromise;
    if (!stripe) {
      log.error("Stripe.js has not been loaded");
      throw new Error("Stripe.js failed to load");
    }
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id
    });

    if (error) {
      posthog.capture("error upgrading", { error });
      log.error("Error:", error);
    }
  };

  return (
    <button onClick={handleClick} disabled={isLoading} className={`upgrade-button2 ${className || ""}`}>
      <div className="text">
        {isLoading ? "Processing..." : children}
      </div>
      <div className="shimmer"></div>
    </button>
  );
};

export default CheckoutButton;