import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../css/menuBar.css";

const TopMenuBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="top-menu-bar">
      <button
        className="back-button"
        onClick={() => navigate(-1)}
        style={{ background: "transparent" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: "orange" }} />
      </button>
      <Link
        to="/chat"
        className={`top-menu-item ${
          location.pathname === "/chat" ? "active-link" : ""
        }`}
      >
        <span>Cast</span>
      </Link>
      <Link
        to="/songs"
        className={`top-menu-item disabled-link ${
          location.pathname === "/songs" ? "active-link" : ""
        }`}
      >
        <span>Fanfic</span>
        <img
          className="soon"
          src={`${process.env.PUBLIC_URL}/soon.svg`}
          alt="soon-svg"
        />
        <span className="soon-text">SOON</span>
      </Link>
      <Link
        to="/shop"
        className={`top-menu-item disabled-link ${
          location.pathname === "/shop" ? "active-link" : ""
        }`}
      >
        <span>Shop</span>
        <img
          className="soon"
          src={`${process.env.PUBLIC_URL}/soon.svg`}
          alt="soon-svg"
        />
        <span className="soon-text">SOON</span>
      </Link>
    </div>
  );
};

export default TopMenuBar;
