import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import axios from "axios";
import { unsubscribeEmail } from "../../api/apiClient";

const UnsubscribePage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const handleUnsubscribe = async () => {
      if (!token) {
        setStatus('error');
        setMessage('Invalid unsubscribe link.');
        return;
      }

      try {
        const response = await unsubscribeEmail(token);
        setStatus('success');
        setMessage(response.message);
      } catch (error) {
        setStatus('error');
        if (axios.isAxiosError(error) && error.response) {
          setMessage(error.response.data.detail || 'An error occurred while unsubscribing.');
        } else {
          setMessage('An unexpected error occurred.');
        }
      }
    };

    handleUnsubscribe();
  }, [token]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={3}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Email Unsubscribe
      </Typography>
      {status === 'loading' && (
        <CircularProgress />
      )}
      {status === 'success' && (
        <Alert severity="success">{message}</Alert>
      )}
      {status === 'error' && (
        <Alert severity="error">{message}</Alert>
      )}
    </Box>
  );
};

export default UnsubscribePage;