import React from "react";
import "../../assets/css/sign_up_modal.css";
import googlelogo from "../../assets/img/google-logo@2x.png";
import { useNavigate } from "react-router-dom";
import LogoComponent from "../miscs/LogoComponent";
import posthog from "posthog-js";
import { setWithExpiry } from "../../helper/storageUtils";

const SignupPrompt = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    posthog.capture("signup_prompt_clicked");
    setWithExpiry("view", "signup", 30 * 1000);
    navigate("/profile");
  };

  return (
    <>
      <div className="modal-backdrop" />
      <div className={"sign-up-prompt"}>
        <h3 className="prompt-header">
          To continue the chat please continue with Google.
        </h3>
        <LogoComponent inline={true} />
        <p className="prompt-text">
          Join the fastest growing community of Series and Film enthusiasts
        </p>

        <div
          className={"continue-with-div-in-chat-" + "google"}
          onClick={navigateToLogin}
          disabled={true}
        >
          <img className={"google-logo"} src={googlelogo} alt="icon-facebook" />
          <div
            className={"continue-with-" + "google" + " inter-bold-white-16px"}
          >
            Sign up with Google
          </div>
        </div>

        <button className={"btn email-btn"} onClick={navigateToLogin}>
          Join with Email
        </button>
      </div>
    </>
  );
};

export default SignupPrompt;
