import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { sendTranscribeMessage } from "../../api/apiClient";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "../../css/MicrophoneComponent.css";
import { TranscriptCallResponseType } from "./InnerChat";
import { getWithoutExpiry } from "../../helper/storageUtils";
import { blobToBase64 } from "../../helper/recordingsHandler";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";

interface MicrophoneProps {
  insertUserMessageTranscript?: (response: TranscriptCallResponseType) => void;
  insertCharacterMessageTranscript?: (response: any) => void;
  character: string;
  movie: string;
  characterTone: string;
  characterTheme: string;
  chatLength: string;
  chatLanguage: string;
  gender?: number;
  setRecording: (response: any) => void;
  setLimitModalVisible: (response: any) => void;
  setAdConsentModal: (response: any) => void;
  setPromptedMessages: (response: any) => void;
  setTemporaryBlob: (response: any) => void;
  actor: string;
}

export const Microphone: FC<MicrophoneProps> = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [micPermissionGranted, setMicPermissionGranted] = useState(false);
  const propsRef = useRef(props);
  const recorderRef = useRef<MicRecorder | null>(null);
  const isMouseDownRef = useRef(false);
  const recordingStartTimeRef = useRef<number | null>(null);

  useEffect(() => {
    propsRef.current = props;

    // Check microphone permission only once
    checkMicrophonePermission();

    // Initialize the recorder
    recorderRef.current = new MicRecorder({ bitRate: 128 });

    // Cleanup on component unmount
    return () => {
      if (recorderRef.current) {
        recorderRef.current.stop();
      }
    };
  }, []);


  const checkMicrophonePermission = async () => {
    if (navigator.mediaDevices) {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setMicPermissionGranted(true);
      } catch
        (error) {
        Sentry.captureException(error);
        log.error(error);
        setMicPermissionGranted(false);
        toast.error("Microphone permission denied or no microphone found.");
      }
    }
  };

  const startRecording = () => {

    if (micPermissionGranted && recorderRef.current && !isRecording) {
      recorderRef.current.start().then(() => {
        setIsRecording(true);
        recordingStartTimeRef.current = Date.now();
      }).catch((e: Error) => {
        toast.error("Failed to start recording. Please check your microphone.");
      });
    } else if (!micPermissionGranted) {
      checkMicrophonePermission().then(() => {
        if (micPermissionGranted && recorderRef.current && !isRecording) {
          recorderRef.current.start().then(() => {
            setIsRecording(true);
            recordingStartTimeRef.current = Date.now();
          }).catch((e: Error) => {
            toast.error("Failed to start recording. Please check your microphone.");
          });
        } else {
          toast.error("Microphone permission denied or no microphone found.");
        }
      });
    }
  };

  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stop().getMp3().then(([buffer, blob]: [ArrayBuffer[], Blob]) => {
        const recordingDuration = Date.now() - (recordingStartTimeRef.current || 0);
        if (recordingDuration >= 1000) {
          const file = new File(buffer, "audio.mp3", {
            type: blob.type,
            lastModified: Date.now()
          });
          handleRecordedBlob(file);
        } else {
          toast.info("Recording too short. Please hold the button for at least 1 second.");
        }

        setIsRecording(false);
        recordingStartTimeRef.current = null;
      }).catch((e: Error) => {
        log.error("Error stopping recording:", e);
        setIsRecording(false);
        recordingStartTimeRef.current = null;
      });
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    isMouseDownRef.current = true;
    startRecording();
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    e.preventDefault();
    isMouseDownRef.current = false;
    stopRecording();
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isMouseDownRef.current) {
      isMouseDownRef.current = false;
      stopRecording();
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    startRecording();
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    stopRecording();
  };

  const handleGlobalMouseUp = () => {
    if (isMouseDownRef.current) {
      isMouseDownRef.current = false;
      stopRecording();
    }
  };

  const handleGlobalTouchEnd = () => {
    if (isRecording) {
      stopRecording();
    }
  };


  const callAndPlayUsingLocalFile = (
    recordedBlob: File,
    characterTone: string,
    characterTheme: string,
    chatLength: string,
    chatLanguage: string,
    setIsRecording: (response: any) => void
  ) => {
    try {
      setIsRecording(true);

      const formData = new FormData();
      formData.append("audio", recordedBlob, "audio.mp3");
      formData.append("message", "");
      formData.append("actor", props.actor);
      formData.append("character", props.character);
      formData.append("series", props.movie);
      formData.append("characterTone", characterTone);
      formData.append("characterTheme", characterTheme);
      formData.append("chatLength", chatLength);
      formData.append("chatLanguage", chatLanguage);

      formData.append(
        "gender",
        props.gender == 1 ? "female" : props.gender == 2 ? "male" : "neutral"
      );
      formData.append("introduction", "false");
      const newUuid = uuidv4();
      formData.append("note_uuid", newUuid);
      const token = getWithoutExpiry(
        `X-Session-Token-${formData.get("character")}`
      );

      setTimeout(() => {
        if (props.insertUserMessageTranscript) {
          const transcriptResponse: TranscriptCallResponseType = {
            transcript: "",
            sent_audio_file: token + "--" + newUuid + ".wav",
            audio_file: "",
            message: "Example message",
            remaining_quota: 0,
            session_token: "example_session_token"
          };
          props.insertUserMessageTranscript(transcriptResponse);
        }
      }, 1200);
      sendTranscribeMessage(formData)
        .then((response) => {
          if (response && response.data) {
            if (
              props.insertUserMessageTranscript &&
              props.insertCharacterMessageTranscript
            ) {
              props.insertUserMessageTranscript(response.data);
              props.insertCharacterMessageTranscript({
                content: response.data.message,
                audio: response.data.audio_file,
                transcript: response.data.message
              });
              if (response.data.follow_up_questions) {
                props.setPromptedMessages(response.data.follow_up_questions);
              }
            }
          } else {
            // Handle the case where response is null
            log.error("No response received from sendTranscribeMessage");
            toast.error("An error occurred while processing your message");
          }
          setIsRecording(false);
        })
        .catch((error) => {
          if (
            axios.isAxiosError(error) &&
            error.response &&
            error.response.data.detail === "QUOTA_EXCEEDED"
          ) {
            // toast.info("No credits remaining.");
            // props.setLimitModalVisible(true);
            props.setAdConsentModal(true);
          } else {
            log.error("Error in sendTranscribeMessage:", error);
            toast.error("An unexpected error occurred");
          }
          setIsRecording(false);
        });
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
      if (error instanceof Error) {
        log.error(error);
        if (error.message === "QUOTA_EXCEEDED") {
          toast.info("No credits remaining.");
          props.setLimitModalVisible(true);
        }
      }
      setIsRecording(false);
    }
  };

  const handleRecordedBlob = (recordedBlob: File) => {
    const currentProps = propsRef.current;
    callAndPlayUsingLocalFile(
      recordedBlob,
      currentProps.characterTone,
      currentProps.characterTheme,
      currentProps.chatLength,
      currentProps.chatLanguage,
      currentProps.setRecording
    );

    blobToBase64(recordedBlob).then((base64String: any) => {
      localStorage.setItem("audioBlob", base64String);
      currentProps.setTemporaryBlob(true);
    });
  };

  return (
    <div className="microphone-container">
      <div className="mic-icon-container" style={{ pointerEvents: "auto" }}>
        <FontAwesomeIcon
          className={`mic-icon ${isRecording ? "recording" : ""}`}
          icon={faMicrophone}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          aria-label={isRecording ? "Stop recording" : "Start recording"}
          role="button"
          style={{ userSelect: "none" }}
        />
        {isRecording && (
          <div className="recording-indicator-wrapper">
            <div className="recording-indicator"></div>
          </div>
        )}
      </div>
    </div>
  );
};