import { API_URL } from "../config";
import { getObjectFromLocal, getWithoutExpiry, setWithoutExpiry } from "../helper/storageUtils";
import { User } from "../types/UserType";
import axios, { AxiosInstance } from "axios";
import { User as FirebaseUser } from "@firebase/auth";

const api: AxiosInstance = axios.create({
  baseURL: `${API_URL}/api`
});

api.defaults.withCredentials = true;

api.interceptors.request.use(
  config => {
    const userId = getWithoutExpiry("User_identifier");
    if (userId) {
      config.headers["User_identifier"] = userId;
    }
    config.headers["ngrok-skip-browser-warning"] = "True";
    return config;
  },
  error => Promise.reject(error)
);

function getAuthHeader() {
  // let access_token;
  const supabaseObject = JSON.parse(
    getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token")
  );
  // if (supabaseObject) {
  const access_token = supabaseObject.access_token;
  // } else {
  //   access_token = getWithoutExpiry("access_token") || "";
  // }

  return `Bearer ${access_token}`;
}

export const login = (email: string, password: string) => {
  return api.post("/token", { username: email, password });
};

export const loginSimple = (email: string) => {
  return api.post("/login", { username: email });
};

export const validateOTP = (email: string, password: string) => {
  return api.post(`/verify-otp/${email}`, { otp: password });
};

export const getUser = async (): Promise<User> => {
  try {
    // let access_token = "";
    const supabaseLogin = getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token");


    // if (!supabaseLogin) {
    //   access_token = getWithoutExpiry("access_token") || "";
    // } else {
    const supabaseObject = JSON.parse(
      supabaseLogin
    );
    // if (supabaseObject) {
    const access_token = supabaseObject.access_token;
    // }
    // }

    const response = await axios.get(API_URL + "/api/users/me/new", {
      headers: {
        "X-Auth-Provider": "SUPABASE",
        Authorization: getAuthHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const applyRetroactiveReferral = async (referralCode: string): Promise<any> => {
  try {
    const response = await api.post("/retroactive-referral", { referral_code: referralCode }, {
      headers: {
        Authorization: getAuthHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const sendReferralCode = async (emailList: string): Promise<any> => {
  try {
    const response = await api.get("/send-referral-code", {
      headers: {
        Authorization: getAuthHeader()
      },
      params: { email_list: emailList }
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const getUserForFirebase = async (user: FirebaseUser): Promise<User> => {
  try {
    const unwrappedUser = user.toJSON();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const accessToken = unwrappedUser?.stsTokenManager?.accessToken;
    const response = await api.post("/users/me/firebase", { accessToken }, {
      headers: {
        Authorization: getAuthHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const getUserForSupabase = async (): Promise<User> => {
  try {
    const supabaseObject = JSON.parse(
      getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token")
    );
    const access_token = supabaseObject.access_token;
    const response = await axios.post(
      API_URL + "/api/users/me/supabase",
      { accessToken: access_token },
      {
        headers: {
          "X-Auth-Provider": "SUPABASE",
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        }
      }
    );
    setWithoutExpiry("access_token", response.data.access_token);
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const createAccount = (email: string, password: string) => {
  return api.post("/create-account", { email, password });
};

export const newLogin = (email: string, password: string | null, otp: string | null) => {
  const data = {
    email: email || null,
    password: password || null,
    otp: otp || null
  };
  return api.post("/new_auth_route", data);
};

export const getToken = (username: string, password: string, on_the_fly = false) => {
  const data = on_the_fly ? { on_the_fly: true } : { username, password };
  return api.post("/token", data);
};

export const getUserDetails = async () => {
  const response = await api.get("/users/me/details", {
    headers: {
      Authorization: getAuthHeader()
    }
  });
  return response.data;
};

export const updateUserDetails = async (details: any) => {
  const response = await api.put("/users/me/details", details, {
    headers: {
      Authorization: getAuthHeader()
    }
  });
  return response.data;
};