import React, { useCallback, useEffect, useRef, useState } from "react";
import { Howl } from "howler";
import "../../assets/css/waveform.scss";
import { getWithoutExpiry } from "../../helper/storageUtils";
import { API_URL } from "../../config";
import { toast } from "react-toastify";
import { getBlobFromLocalStorage, saveBlobToLocalStorage } from "../../helper/recordingsHandler";
import log from "loglevel";

interface WaveformProps {
  audioUrl: string;
  isRecording?: boolean;
  blobUrl?: string;
  isLastMessage?: boolean;
}

const Waveform: React.FC<WaveformProps> = ({
                                             audioUrl,
                                             isRecording = false,
                                             blobUrl,
                                             isLastMessage
                                           }) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>("0:00");
  const [animate, setAnimate] = useState(false);
  const [localAudioUrl, setLocalAudioUrl] = useState<string | null>(null);
  const soundRef = useRef<Howl | null>(null);
  const [progress, setProgress] = useState(0);
  const fetchingRef = useRef<boolean>(false);


  const loadAudio = useCallback((url: string) => {
    if (soundRef.current) {
      soundRef.current.unload();
    }

    soundRef.current = new Howl({
      src: [url],
      html5: true,
      format: ["mp3", "wav"],
      onload: () => {
        setDuration(formatDuration(soundRef.current?.duration() || 0));
      },
      onplay: () => setPlaying(true),
      onpause: () => setPlaying(false),
      onend: () => {
        setPlaying(false);
        setProgress(0);
      },
      onseek: () => {
        setProgress((soundRef.current?.seek() as number) / (soundRef.current?.duration() as number) * 100);
      }
    });

    const progressInterval = setInterval(() => {
      if (soundRef.current && soundRef.current.playing()) {
        setProgress((soundRef.current.seek() as number) / (soundRef.current.duration() as number) * 100);
      }
    }, 100);

    return () => clearInterval(progressInterval);
  }, []);


  const fetchAudio = useCallback((retryCount = 5) => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;

    fetch(`${API_URL}/api/audio/${audioUrl}`, {
      headers: {
        Authorization: `Bearer ${getWithoutExpiry("access_token") || ""}`
      }
    })
      .then((response) => {
        if (!response.ok && response.status === 404 && retryCount > 0) {
          setTimeout(() => fetchAudio(retryCount - 1), 2000);
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          saveBlobToLocalStorage(audioUrl, blob);
          const url = URL.createObjectURL(blob);
          setLocalAudioUrl(url);
          loadAudio(url);
        }
      })
      .catch((error) => {
        toast.error("Error fetching audio file.");
        log.error("Failed to load audio:", error);
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [audioUrl]);

  useEffect(() => {
    setAnimate(true);

    const cachedBlob = getBlobFromLocalStorage(audioUrl);
    if (cachedBlob) {
      const url = URL.createObjectURL(cachedBlob);
      setLocalAudioUrl(url);
      loadAudio(url);
    } else {
      fetchAudio();
    }

    return () => {
      if (localAudioUrl) {
        URL.revokeObjectURL(localAudioUrl);
      }
      if (soundRef.current) {
        soundRef.current.unload();
      }
    };
  }, [audioUrl, blobUrl, isLastMessage, fetchAudio, loadAudio]);

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const handlePlay = () => {
    if (soundRef.current) {
      if (playing) {
        soundRef.current.pause();
      } else {
        soundRef.current.play();
      }
    }
  };

  return (
    <div className={`parent-container ${animate ? "slide-in" : ""}`}>
      <div className="waveform-container">
        <button className="button play-button" onClick={handlePlay} disabled={isRecording}>
          {!playing ? (
            <svg className="button-icon" viewBox="5.0 0.0 50 50">
              <polygon className="triangle" points="16.67,10.00 41.67,25.00 16.67,40.00" />
            </svg>
          ) : (
            <svg className="button-icon" viewBox="0 0 60 60">
              <rect className="rectangle" x="10.0" y="15" width="10.0" height="30" />
              <rect className="rectangle" x="30.0" y="15" width="10.0" height="30" />
            </svg>
          )}
        </button>
        <div className="wave">
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div className="secs" style={{ fontSize: "7pt" }}>
        <div>{duration}</div>
      </div>
    </div>
  );
};

export default Waveform;