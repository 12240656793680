// helper/SentryProvider.tsx

import React, { ReactNode, useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Define the props interface
interface SentryProviderProps {
  children: ReactNode;
}

// Define the component with typed props
const SentryProvider: React.FC<SentryProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    // Guard to prevent multiple initializations
    if (!Sentry.getCurrentHub().getClient()) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, [location, navigationType]);

  return <>{children}</>;
};

export default SentryProvider;
