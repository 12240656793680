import React, { useEffect, useState } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import { ArrowRight, CheckCircle, ChevronRight, Share2, X } from "lucide-react";
import { Helmet } from "react-helmet";
import log from "loglevel";

type WaitlistComponentProps = {
  supabase: SupabaseClient;
};
const WaitlistComponent: React.FC<WaitlistComponentProps> = ({ supabase }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [referrerCode, setReferrerCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>("Welcome aboard!");

  useEffect(() => {
    // Generate a new invite code for this user
    const code = generateInviteCode();
    setInviteCode(code);

    // Check for referrer code in URL
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get("invite");

    if (referrer) {
      setReferrerCode(referrer);
    }
  }, []);

  const generateInviteCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error state

    try {
      // Check if email already exists
      const { data: existingUser, error: checkError } = await supabase
        .from("waitlist")
        .select("email, invite_code")
        .eq("email", email)
        .maybeSingle();

      if (checkError) {
        log.error("Error checking existing user:", checkError);
        setError("An error occurred. Please try again.");
        return;
      }

      if (existingUser) {
        const referralLink = `${window.location.origin}/waitlist?invite=${existingUser.invite_code}`;
        setError(`You're already on the waitlist! Here's your referral link: ${referralLink}`);
        setInviteCode(existingUser.invite_code);
        setIsSubmitted(true);
        setSuccessMessage("Welcome back!");
        return;
      }

      // If email doesn't exist, proceed with insertion
      const { data, error } = await supabase
        .from("waitlist")
        .insert({
          email,
          name,
          invite_code: inviteCode,
          referrer_code: referrerCode || null
        })
        .select();

      if (data) {
        setIsSubmitted(true);
        if (referrerCode) {
          await updateReferralCount(referrerCode);
        }
      } else if (error) {
        log.error("Error submitting to waitlist:", error);
        setError("An error occurred while joining the waitlist. Please try again.");
      }
    } catch (error) {
      log.error("Unexpected error:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleShare = async () => {
    // Use the correct path for the waitlist page
    const shareUrl = `${window.location.origin}/waitlist?invite=${inviteCode}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: "Join our exclusive waitlist!",
          text: "I'm excited about this new product. Use my invite link to join the waitlist!",
          url: shareUrl
        });
      } catch (err) {
        log.error("Error sharing:", err);
      }
    } else {
      setShowShareModal(true);
    }
  };

  const updateReferralCount = async (referrerCode: string) => {
    // Fetch the current referral count
    const { data, error: fetchError } = await supabase
      .from("waitlist")
      .select("referral_count")
      .eq("invite_code", referrerCode)
      .single();

    if (fetchError) {
      log.error("Error fetching referral count:", fetchError);
      return;
    }

    // Increment the referral count
    const newCount = (data?.referral_count || 0) + 1;

    const { error: updateError } = await supabase
      .from("waitlist")
      .update({ referral_count: newCount })
      .eq("invite_code", referrerCode);

    if (updateError) {
      log.error("Error updating referral count:", updateError);
    }
  };

  const handleManualShare = () => {
    // Use the correct path for the waitlist page
    const shareUrl = `${window.location.origin}/waitlist?invite=${inviteCode}`;
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        alert("Invite link copied to clipboard!");
      })
      .catch(err => log.error("Error copying to clipboard:", err));
  };


  return (
    <>
      <Helmet>
        <title>Search - My App</title>
        <meta name="description" content="Waitlist" />
        <meta property="og:title" content="Waitlist - Scenextras" />
        <meta property="og:description" content="Waitlist - Join our waitlist." />
        <meta property="og:image" content="https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png" />
      </Helmet>
      <div className="waitlist-container">
        <header className="waitlist-header">
          <h1>Waitlist</h1>
          <a href="https://scenextras.com/" target="_blank" rel="noopener noreferrer">
            <button className="learn-more-btn">
              Learn more <ArrowRight size={16} />
            </button>
          </a>
        </header>

        {!isSubmitted ? (
          <>
            <h2 className="main-title" style={{ fontSize: "1.2em", marginBottom: "0" }}>
              Characters cant hear you?
              <br />
              We gave them ears <br />

              Talk to Characters you love <br />
            </h2>
            <h3 className="sub-title" style={{ fontSize: "1.3em", marginTop: "20px" }}>
              Join the waitlist, main character.
            </h3>

            <form onSubmit={handleSubmit} className="waitlist-form">
              <div className="form-group">
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder=" "
                />
                <label htmlFor="name">Name</label>
              </div>
              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder=" "
                />
                <label htmlFor="email">Email</label>
              </div>
              {error && <p className="error-message">{error}</p>}
              <button type="submit" className="submit-btn">
                Join Waitlist
                <ChevronRight size={20} />
              </button>
            </form>
          </>
        ) : (
          <div className="success-message">
            <CheckCircle size={48} color="#ffd700" />
            <h2>{successMessage}</h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>We're thrilled to have you join us. Share your invite link to move up the list!</p>
            <button onClick={handleShare} className="share-btn">
              <Share2 size={20} />
              Share Invite Link
            </button>
          </div>
        )}

        {showShareModal && (
          <div className="share-modal">
            <button className="close-modal" onClick={() => setShowShareModal(false)}>
              <X size={24} />
            </button>
            <h3>Share your invite link</h3>
            <p>Copy this link and share it with your friends:</p>
            <input
              type="text"
              readOnly
              value={`${window.location.origin}/waitlist?invite=${inviteCode}`}
              onClick={(e) => (e.target as HTMLInputElement).select()}
            />
            <button onClick={handleManualShare} className="copy-btn">
              Copy to Clipboard
            </button>
          </div>
        )}

        <footer className="waitlist-footer">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          We can't wait to reveal what we've been working on. Stay tuned!
        </footer>
      </div>
    </>
  );
};

const styles = `
  .error-message {
    color: #ff6b6b;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .waitlist-container {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .waitlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }

  .waitlist-header h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .learn-more-btn {
    background: none;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .learn-more-btn:hover {
    text-shadow: 0 0 10px rgba(255,255,255,0.5);
  }

  .main-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    text-align: center;
  }

  .sub-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
    color: #FF8C00;
    text-align: center;
  }

  .waitlist-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .form-group {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-group input {
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #ffffff;
    font-size: 1rem;
    transition: all 0.3s ease;
  }

  .form-group input:focus {
    outline: none;
    border-color: #FF8C00;
    box-shadow: 0 0 15px rgba(255, 140, 0, 0.3);
  }

  .form-group label {
    position: absolute;
    left: 1rem;
    top: 1rem;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease;
    pointer-events: none;
  }

  .form-group input:focus + label,
  .form-group input:not(:placeholder-shown) + label {
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.8rem;
    color: #FF8C00;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    padding: 0 0.5rem;
  }

  .submit-btn, .share-btn {
    background: #FF8C00;
    color: #000000;
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: auto;
    width: 100%;
    max-width: 200px;
  }

  .submit-btn:hover, .share-btn:hover {
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(255, 140, 0, 0.4);
    transform: translateY(-2px);
  }

  .success-message {
    text-align: center;
    animation: fadeIn 0.5s ease-out;
  }

  .success-message h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .success-message p {
    font-size: 1.1rem;
    opacity: 0.9;
    margin-bottom: 1.5rem;
  }

  .waitlist-footer {
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.7;
    margin-top: 2rem;
  }

  .share-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9);
    padding: 2rem;
    border-radius: 10px;
    z-index: 1000;
    text-align: center;
  }

  .close-modal {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }

  .share-modal h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .share-modal p {
    margin-bottom: 1rem;
  }

  .share-modal input {
    width: 100%;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    color: #ffffff;
    font-size: 0.9rem;
    text-align: center;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;
const StyledWaitlistComponent: React.FC<{ supabase: SupabaseClient }> = ({ supabase }) => (
  <>
    <style>{styles}</style>
    <WaitlistComponent supabase={supabase} />
  </>
);

export default StyledWaitlistComponent;