import React from "react";

interface SignUpProps {
  inline?: boolean;
}

const LogoComponent: React.FC<SignUpProps> = ({ inline = false }) => {
  return (
    <img
      alt="full-logo"
      className={inline ? "full-logo-inline" : "full-logo"}
      src={`${process.env.PUBLIC_URL}/full_logo.svg`}
    />
  );
};

export default LogoComponent;
