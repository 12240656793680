import { Star } from "lucide-react"

interface StarRatingProps {
  rating: number
}

export default function StarRating({ rating }: StarRatingProps = { rating: 0 }) {
  const fullStars = Math.floor(rating / 2)
  const hasHalfStar = rating % 2 >= 1

  return (
    <div className="flex flex-col items-start">
      <div className="flex">
        {[...Array(5)].map((_, index) => (
          <Star
            key={index}
            className={`w-6 h-6 ${
              index < fullStars || (index === fullStars && hasHalfStar)
                ? 'text-yellow-400'
                : 'text-gray-600'
            }`}
            fill={index < fullStars ? 'currentColor' : 'none'}
          />
        ))}
      </div>
      <span className="text-sm font-medium text-white mt-1">{rating.toFixed(1)} / 10</span>
    </div>
  )
}