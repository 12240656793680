import React, { useEffect, useRef, useState } from "react";
import { ActorFilmography, Character, Movie } from "../../types/MovieType";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import log from "loglevel";

export interface DisplayProps {
  items: (Movie | ActorFilmography)[];
  displayMode?: string;
  type: "movie" | "actor";
}



const Display: React.FC<DisplayProps> = ({ items, displayMode, type }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const navigate = useNavigate();
  const displayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (displayMode === "trending" && displayRef.current) {
      gsap.fromTo(
        displayRef.current.children,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
      );
    }
  }, [displayMode]);

  const handleNavigate = (item: Movie | ActorFilmography) => {
    log.info("item", item);
    if (navigate) {
      let navigateItem: Movie;

      if (type === "actor" && 'filmography' in item ) {
        navigateItem = {
          adult: false,
          backdrop_path: "",
          genre_ids: [],
          id: 0,
          original_language: "en",
          original_title: item.name || "",
          overview: "",
          popularity: 0,
          poster_path: getProfileImageUrl(item) || "",
          release_date: "",
          title: item.name || "",
          video: false,
          vote_average: 0,
          vote_count: 0,
          cast: item.filmography.characters ,
          random_index: 0,
          source: "actor_filmography",
          actorInfo: item
        };
      } else if ('title' in item) {
        navigateItem = item;
      } else {
        navigate("/chat", { state: { movie: item, chat_type: type === "actor" ? "actor" : "movie" } });
        return;
      }

      navigate("/chat", { state: { movie: navigateItem, chat_type: type === "actor" ? "actor" : "movie" } });
    }
  };

  const handleClick = (item: Movie | ActorFilmography, index: number) => {
    setSelectedItem(index);
    setTimeout(() => handleNavigate(item), 300);
  };

  const getProfileImageUrl = (item: ActorFilmography) => {
    if (!item.profile_path) {
      return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
    }

    if (item.profile_path.includes("/static/media/")) {
      return item.profile_path;
    }

    if (item.profile_path.startsWith("https")) {
      return item.profile_path;
    }

    return `https://image.tmdb.org/t/p/w500${item.profile_path}`;
  };

  const getImageSrc = (item: Movie | ActorFilmography): string => {
    if (type === "movie") {
      const movie = item as Movie;
      return movie.source === "anime"
        ? movie.poster_path
        : `https://image.tmdb.org/t/p/w500${movie.poster_path}`;
    } else {
      const actor = item as ActorFilmography;
      return getProfileImageUrl(actor);
    }
  };

  const getTitle = (item: Movie | ActorFilmography): string => {
    if (type === "movie") {
      return (item as Movie).title;
    } else {
      return (item as ActorFilmography).name;
    }
  };

  return (
    <div
      className={`movie-display ${
        displayMode === "trending" ? "movie-display-trending" : ""
      }`}
      ref={displayRef}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`movie ${
            displayMode === "trending" ? "movie-trending" : ""
          } ${selectedItem === index ? "enlarge" : ""}`}
          onClick={() => handleClick(item, index)}
        >
          <img
            src={getImageSrc(item)}
            alt={getTitle(item)}
            className="movie-poster"
          />
          {/*<div className="item-title">{getTitle(item)}</div>*/}
        </div>
      ))}
    </div>
  );
};

export default Display;