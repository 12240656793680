import React, { useEffect, useRef, useState } from "react";
import { getPopularMoviesWithCast, getRandomMovieCharacters } from "../../api/apiClient";
import "../../assets/css/chat.css";
import { gsap } from "gsap";
import scenegptEatingPopcorn from "../../assets/lotties/scenegpt-eating-popcorn.png";
import { Movie, SeriesWithCast } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import TopMenuBar from "../template/TopMenuBar";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/scenextras_logo.svg";
import {
  getWithExpiry,
  getWithoutExpiry,
  RECENT_CHARACTERS_KEY,
  setWithExpiry,
  setWithoutExpiry
} from "../../helper/storageUtils";
import "../../assets/css/chatmenubtn.scss";
import SignUpModal from "../modals/SignUpModal";
import Loading from "../miscs/Loading";
import InnerChat from "./InnerChat";
import posthog from "posthog-js";
import AdConsentModal from "../modals/AdConsentModal";
import { toast } from "react-toastify";
import log from "loglevel";
import SurveyModal from "../modals/SurveyModal";
import { AuthenticatedUser } from "../../helper/authHelper";
import Seo from "../../helper/Seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImdb, faFacebook, faInstagram, faTwitter, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";


interface Message {
  sender: "user" | "character";
  content: string;
  audio?: string;
}

interface TranscriptCallResponseType {
  transcript: string;
  sent_audio_file: string;
  audio_file: string;
  message: string;
  remaining_quota: number;
  session_token: string;
}

interface ChatProps {
  movie?: Movie;
  chat_type?: string;
  character?: Cast;
  cast?: Cast[];
  user?: AuthenticatedUser;
}

interface LocationState {
  movie: Movie;
}

const sceneGPTCharacter: Cast = {
  adult: false,
  gender: 0,
  id: 99999,
  known_for_department: "Suggestions",
  name: "Streaming Companion, AMA!",
  original_name: "Scenester",
  popularity: 10,
  profile_path: scenegptEatingPopcorn,
  cast_id: 123,
  character: "Scenester",
  credit_id: "abc123",
  order: 1,
  from_movie: "",
  alt: "SceneGPT eating popcorn"
};


const Chat: React.FC<ChatProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const consolidatedProps = {
    ...props,
    ...location.state
  };

  const {
    movie,
    character,
    chat_type,
    animate,
    cast,
    user
  } = consolidatedProps;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCast, setFilteredCast] = useState<Cast[]>([]);
  const [isResourceLoading, setResourceIsLoading] = useState(false);
  const [castState, setCastState] = useState<Cast[]>([]);
  const [gender, setGender] = useState<number | undefined>(undefined);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [limitModalVisible, setLimitModalVisible] = useState(false);
  const [adConsentModal, setAdConsentModal] = useState(false);
  const [surveyModal, setSurveyModal] = useState(false);
  const [chatType, setChatType] = useState<string>(chat_type);
  const [selectedCharacterObject, setSelectedCharacterObject] = useState<Cast | undefined>(location.state?.character);
  const [movieObject, setMovieObject] = useState<Movie | SeriesWithCast>(movie);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);

  const chatContainerStyle = animate
    ? { animation: "slideInFromRight 0.5s forwards" }
    : {};

  const chatContainerClass = animate ? "slide-in-right" : "";

  useEffect(() => {
    try {
      setResourceIsLoading(true);
      if (chatType == "popular") {
        const storedPopularMovies: Movie[] = JSON.parse(getWithExpiry("popular_movies_aggregated"));
        if (storedPopularMovies) {
          const firstCastFromEachMovie = storedPopularMovies
            .map((movie) => movie.cast && movie.cast.length > 0 ? {
              ...movie.cast[0],
              from_movie: movie.title || movie.original_title
            } : null)
            .filter((cast) => cast !== null);
          setMovieObject({ ...movieObject, cast: firstCastFromEachMovie as Cast[] });
        } else {
          getPopularMoviesWithCast().then((response) => {
            // Get the first cast from each movie
            const firstCastFromEachMovie = response
              .map((movie) => movie.cast && movie.cast.length > 0 ? {
                ...movie.cast[0],
                from_movie: movie.title || movie.original_title
              } : null)
              .filter((cast) => cast !== null);
            setMovieObject({ ...movieObject, cast: firstCastFromEachMovie as Cast[] });
            setWithExpiry(
              "popular_movies_aggregated",
              JSON.stringify(response),
              3 * 60 * 1000
            );
          });
        }
        setResourceIsLoading(false);
      } else if (chatType === "sceneGPT") {
        setGender(0);
        setSelectedCharacterObject({ character: "sceneGPT", profile_path: "", name: "sceneGPT", from_movie: "" });
      } else if (chatType === "carousel") {
        setSelectedCharacterObject(character);
        setMovieObject({ ...movieObject, cast: cast as Cast[] });
      }
      setResourceIsLoading(false);
    } catch (e) {
      log.error(e);
      toast.error("Error fetching cast data");
    }
  }, []);

  useEffect(() => {
    if (selectedCharacterObject) {
      setGender(selectedCharacterObject.gender || 0);
    } else {
      setGender(0);
    }
  }, [selectedCharacterObject]);

  useEffect(() => {
    if (searchTerm.trim()) {
      const lowercasedTerm = searchTerm.toLowerCase();
      if (chatType != "actor") {
        setFilteredCast(
          [sceneGPTCharacter, ...movieObject.cast].filter((char) =>
            char.name.toLowerCase().includes(lowercasedTerm) ||
            char.character.toLowerCase().includes(lowercasedTerm) ||
            (char.from_movie && char.from_movie.toLowerCase().includes(lowercasedTerm))
          )
        );
      } else {
        setFilteredCast(movieObject.cast.filter(char =>
          char.name.toLowerCase().includes(lowercasedTerm) ||
          char.character.toLowerCase().includes(lowercasedTerm) ||
          (char.from_movie && char.from_movie.toLowerCase().includes(lowercasedTerm))
        ));
      }
    } else if (movieObject) {

      if (chatType != "actor") {
        setFilteredCast([sceneGPTCharacter, ...movieObject.cast]);
      } else {
        setFilteredCast(movieObject.cast);
      }


    }
  }, [searchTerm, movieObject]);

  useEffect(() => {
    if (movieObject) {
      const sceneGPTIndex = movieObject.cast.findIndex(char => char.id === 99999);
      if (sceneGPTIndex === -1) {
        if (chatType != "actor") {
          setFilteredCast([sceneGPTCharacter, ...movieObject.cast]);
        } else {
          setFilteredCast(movieObject.cast);
        }
      } else {
        setFilteredCast(movieObject.cast);
      }
    }
  }, [movieObject]);

  const chatContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContentRef.current) {
      gsap.from(chatContentRef.current, {
        duration: 1,
        opacity: 0,
        y: -20,
        ease: "power2.out"
      });
    }
  }, [chatContentRef, location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      window.scrollTo(0, 0);
      // Force re-render
      setChatMessages([...chatMessages]);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [chatMessages]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const saveRecentCharacter = (character: Cast, movieObject: Movie | SeriesWithCast) => {
    // Get existing recent characters from localStorage
    if (!character.from_movie) {
      character.from_movie = movieObject.title;
    }

    let recentCharacters: Cast[] = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];

    // Remove the character if it already exists in the list
    recentCharacters = recentCharacters.filter(c => c.id !== character.id);

    // Add the new character to the beginning of the list
    recentCharacters.unshift(character);

    // Save the updated list back to localStorage
    setWithoutExpiry(RECENT_CHARACTERS_KEY, recentCharacters);
  };

  const handleCharacterSelect = (character: Cast) => {
    log.info(character);
    log.info("handleCharacterSelect: " + character.name);
    log.info(movieObject);
    if (!movieObject) {
      setMovieObject({ title: character.from_movie } as Movie);
    }
    if (chat_type != "movie") {
      setMovieObject({ ...movieObject, title: character.from_movie });
    }

    setSelectedCharacterObject(character);

    setTimeout(function() {
      saveRecentCharacter(character, movieObject);
    }, 5000);
  };

  const changeCharacterInfiniteScroll = async () => {
    setResourceIsLoading(true);
    try {
      const response: Movie | SeriesWithCast = await getRandomMovieCharacters();
      if (response && response.cast) {
        setMovieObject(response);
        setSelectedCharacterObject(response.cast[response.random_index]);

        posthog.capture("infinite_scroll", {
          movie: response.title,
          character: response.cast[response.random_index].character
        });
      }
    } catch (e) {
      log.error(e);
      toast.error("Error fetching cast data");
    }
    setResourceIsLoading(false);
  };


  const setNextCharacter = () => {
    if (movieObject.cast) {
      const currentIndex = movieObject.cast.findIndex(
        (c: Cast) => c === selectedCharacterObject
      );
      const nextIndex = (currentIndex + 1) % movieObject.cast.length;
      const nextCharacter = movieObject.cast[nextIndex];
      posthog.capture("next_character", { character: nextCharacter });
      setSelectedCharacterObject(nextCharacter);
    }
  };

  const setPreviousCharacter = () => {
    const cast = getWithExpiry("last_cast_search");
    if (!cast || cast.length === 0) {
      log.error("Cast is null or empty");
      return;
    }
    const currentIndex = cast.findIndex(
      (c: Cast) => c === selectedCharacterObject
    );
    const prevIndex = (currentIndex - 1 + cast.length) % cast.length;
    const prevCharacter = cast[prevIndex];
    posthog.capture("previous_character", { character: prevCharacter.character });
    setSelectedCharacterObject(prevCharacter);
  };

  const svgBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}background.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100%"
  };
  const hasConsented = getWithoutExpiry("adConsent");

  function getCharacterAvatarSrc(profilePath: string | null): string {
    if (!profilePath) {
      return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
    }

    if (profilePath.includes("/static/media/")) {
      return profilePath;
    }

    return profilePath.startsWith("https")
      ? profilePath
      : `https://image.tmdb.org/t/p/w500${profilePath}`;
  }

  return (
    <>
      <Seo
        title={"Chat - Scenextras"}
        description={"Chat - Scenextras - with your favourite characters."}
        url={`https://chat.scenextras.com/chat`}
        image={"https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"}
      />
      {isResourceLoading && <Loading />}
      <div style={svgBackground}>
        <div
          className="top-bar"
          onClick={() => {
            navigate("/");
          }}
        >
          <img className="logo" src={logo} alt="Logo" />
        </div>
        {!selectedCharacterObject && (
          <>
            <TopMenuBar />
            <div className="movie-header">
              {chatType === "actor" && (
                <div className="poster-container">
                  <img
                    src={movieObject.poster_path}
                    alt={movieObject.title}
                    className="movie-poster"
                  />
                </div>
              )}
              <p data-id={movieObject ? movieObject.title : ""} className="cast-headline">
                {chatType == "popular" ? "Top 20 Characters" : chatType == "sceneGPT" ? "SceneGPT" : movieObject.title}
              </p>
            </div>
            {chatType === "actor" && movieObject.actorInfo?.external_ids && (
              <div className="social-icons">
                {movieObject.actorInfo.external_ids.imdb_id && (
                  <a href={`https://www.imdb.com/name/${movieObject.actorInfo.external_ids.imdb_id}`} target="_blank"
                     rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faImdb} />
                  </a>
                )}
                {movieObject.actorInfo.external_ids.twitter_id && (
                  <a href={`https://twitter.com/${movieObject.actorInfo.external_ids.twitter_id}`} target="_blank"
                     rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                )}
                {movieObject.actorInfo.external_ids.wikidata_id && (
                  <a href={`https://www.wikidata.org/wiki/${movieObject.actorInfo.external_ids.wikidata_id}`}
                     target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGlobe} />
                  </a>
                )}
              </div>
            )}
          </>
        )}
        {!selectedCharacterObject && (
          <div className="character-search-bar">
            <input
              type="text"
              placeholder="Search characters..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        )}
        {selectedCharacterObject ? (
          <InnerChat
            chat_type={chatType}
            setSelectedCharacterObject={setSelectedCharacterObject}
            selectedCharacterObject={selectedCharacterObject}
            ratingModalVisible={ratingModalVisible}
            changeCharacterInfiniteScroll={changeCharacterInfiniteScroll}
            setPreviousCharacter={setPreviousCharacter}
            setNextCharacter={setNextCharacter}
            signUpModalVisible={signUpModalVisible}
            setSignUpModalVisible={setSignUpModalVisible}
            setRatingModalVisible={setRatingModalVisible}
            movie={movieObject}
            setLimitModalVisible={setLimitModalVisible}
            setAdConsentModal={setAdConsentModal}
            setSurveyModal={setSurveyModal}
            user={user}
          />
        ) : (
          <>
            {chatType == "actor" ? (
              // AUTOR FLOW
              <div
                className={`chat-container chat-container-not-in-chat ${chatContainerClass}`}
                style={chatContainerStyle}
              >
                <div className="character-list">
                  {filteredCast &&
                    filteredCast.length > 0 &&
                    filteredCast.map((inner_char, index) => (
                      <div key={index}>
                        <div
                          className={`character-list-item`}
                          onClick={() => handleCharacterSelect(inner_char)}
                        >
                          <div className="character-container">
                            <img
                              className="character-avatar"
                              src={getCharacterAvatarSrc(inner_char.profile_path)}
                              alt={inner_char.id === 99999 && inner_char.alt ? inner_char.alt : `${inner_char.name} Avatar`}
                            />
                            <div className="character-info">
                              <span className="character-name">{inner_char.name.replace("(voice)", "")}</span>
                              {inner_char.from_movie && (
                                <span className="character-movie">{inner_char.from_movie}</span>
                              )}
                            </div>
                          </div>
                          <div>
                            <img
                              className="chat-icon"
                              src={`${process.env.PUBLIC_URL}/chat_icon.svg`}
                              alt="chat-icon"
                            />
                          </div>
                        </div>
                        {inner_char.id === 99999 && (
                          <div className="scenegpt-delimiter">
                            <hr className="scenegpt-hr" />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div
                className={`chat-container chat-container-not-in-chat ${chatContainerClass}`}
                style={chatContainerStyle}
              >
                <div className="character-list">
                  {filteredCast &&
                    filteredCast.length > 0 &&
                    filteredCast.map((inner_char, index) => (
                      <div key={index}>
                        <div
                          className={`character-list-item ${inner_char.id === 99999 ? "scenegpt-character" : ""}index`}
                          onClick={() => handleCharacterSelect(inner_char)}
                        >
                          <div className="character-container">
                            <img
                              className="character-avatar"
                              src={getCharacterAvatarSrc(inner_char.profile_path)}
                              alt={inner_char.id === 99999 && inner_char.alt ? inner_char.alt : `${inner_char.name} Avatar`}
                            />
                            <div className="character-name">
                              <span>{inner_char.character.replace("(voice)", "")}</span>
                              <br />
                              <span className="character-name-small">{inner_char.name}</span>
                            </div>
                          </div>
                          {chatType != "history" ? (
                            <>
                              <div>
                                <img
                                  className="chat-icon"
                                  src={`${process.env.PUBLIC_URL}/chat_icon.svg`}
                                  alt="chat-icon"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              {inner_char.lastMessageTime && (
                                <div className="time-ago-container">

                                  <div className="time-ago">{inner_char.lastMessageTime}</div>
                                  {index === 1 && <div className="notification-count">1</div>}
                                </div>
                              )}
                            </>
                          )
                          }
                        </div>
                        {inner_char.id === 99999 && (
                          <div className="scenegpt-delimiter">
                            <hr className="scenegpt-hr" />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {ratingModalVisible && (
        // <RatingModal onClose={() => setRatingModalVisible(false)}/>
        <></>
      )}

      {signUpModalVisible && <SignUpModal />}

      {/* LIMIT MODAL DISABLED FOR NOW*/}
      {/*{limitModalVisible && <LimitModal setVisibility={setLimitModalVisible} />}*/}
      {adConsentModal && (
        <AdConsentModal setVisibility={setAdConsentModal} />
      )}


      {
        surveyModal && <SurveyModal setVisibility={setSurveyModal} />
      }
    </>
  );
};

export default Chat;