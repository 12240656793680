import React, { FC, useEffect, useState } from "react";
import CornerMaxButton from "../miscs/CornerMaxButton";

interface ChatMenuProps {
  toggleMicrophone: () => void;
  removeMessagesForCharacter: () => void;
  toggleFollowups: () => void;
  toggleWorldSim: () => void;
  microphoneEnabled: boolean;
  followupEnabled: boolean;
  currentCharacter: string;
  setTemporaryConfig: () => void;
}

const ChatMenuButton: FC<ChatMenuProps> = ({
                                             toggleMicrophone,
                                             removeMessagesForCharacter,
                                             toggleFollowups,
                                             toggleWorldSim,
                                             microphoneEnabled,
                                             followupEnabled,
                                             setTemporaryConfig,
                                             currentCharacter
                                           }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".list-container")) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderMenuItems = () => {
    if (currentCharacter === "Scenester") {
      return (
        <li
          className="more-button-list-item"
          onClick={() => removeMessagesForCharacter()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-copy"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
          </svg>
          <span>Clear Chat</span>
        </li>
      );
    }

    return (
      <>
        <li
          className={`more-button-list-item ${microphoneEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleMicrophone()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-copy"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
          </svg>
          <span>Voice Chat</span>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => removeMessagesForCharacter()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-copy"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
          </svg>
          <span>Clear Chat</span>
        </li>
        <li
          className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleFollowups()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
            </svg>
            <span>Follow ups</span>
          </CornerMaxButton>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => toggleWorldSim()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
            </svg>
            <span>WorldSim</span>
          </CornerMaxButton>
        </li>
        <li
          className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}
          onClick={() => setTemporaryConfig()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
            </svg>
            <span>Roles</span>
          </CornerMaxButton>
        </li>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className={`list-container ${isActive ? "active" : ""}`}>
        <button
          className="more-button"
          aria-label="Menu Button"
          onClick={handleClick}
        >
          <div className="menu-icon-wrapper">
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
          </div>
        </button>
        <ul className="more-button-list">
          {renderMenuItems()}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default ChatMenuButton;