import React from "react";
import { User } from "../../types/UserType";

interface UnsubscribeModalProps {
  setVisibility: (visible: boolean) => void;
  onConfirm: () => void;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ setVisibility, onConfirm }) => {
  return (
    <div className="ad-consent-modal">
      <div className="modal-content">
        <div className="modal-header">
          <button className="close-btn" onClick={() => setVisibility(false)}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <h2>Confirm Unsubscription</h2>
          <p>
            Are you sure you want to unsubscribe? Your subscription will be cancelled at the end of the current billing period.
          </p>
        </div>
        <div className="modal-footer">
          <button onClick={() => setVisibility(false)} className="accept-btn">
            Cancel
          </button>
          <span onClick={onConfirm} className="cancel-btn">
            Confirm Unsubscribe
          </span>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeModal;