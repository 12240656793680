// index.tsx

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// External Libraries
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";
import log from "loglevel";

// Internal Modules
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { isAnalyticsEnabled } from "./config";
import SentryProvider from "./helper/SentryProvider";

// Styles
import "./assets/css/index.css";
import { validateEnvVariables } from "./helper/envValidator";
import ErrorBoundary from "./ErrorBoundary";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


/**
 * Initialize Analytics (PostHog) if enabled
 */
if (isAnalyticsEnabled()) {
  log.setLevel("silent");
  // log.info("Environment Variables:", process.env);
} else {
  log.enableAll();
}

/**
 * Validate Required Environment Variables
 */
validateEnvVariables([
  "REACT_APP_SUPABASE_KEY",
  "REACT_APP_SUPABASE_URL",
  "REACT_APP_ENV",
  "REACT_APP_AUTH_REDIRECT_URL",
  "REACT_APP_BACKEND_ENV"
]);

/**
 * Initialize Sentry
 * Note: It's assumed that SentryProvider handles the Sentry initialization.
 * If not, consider initializing Sentry here or within SentryProvider.
 */

// Create Root and Render Application
const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <SentryProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </SentryProvider>
      </PostHogProvider>
    </BrowserRouter>
  </React.StrictMode>
);

/**
 * Report Web Vitals
 * For more information, visit: https://bit.ly/CRA-vitals
 */
reportWebVitals();
