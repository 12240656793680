import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  createTheme,
  ThemeProvider,
  IconButton,
  Tooltip,
  CircularProgress,
  Snackbar,
  styled,
  BoxProps
} from "@mui/material";
import {
  ArrowBack,
  GetApp,
  Image,
  Twitter,
  Facebook,
  WhatsApp,
  Telegram,
  Link as LinkIcon
} from "@mui/icons-material";
import { AuthenticatedUser } from "../../helper/authHelper";
import { handleApiFetch, handleApiSave } from "../../api/apiClient";
import { toast } from "react-toastify";

export const useSaveAndShare = (user: AuthenticatedUser | undefined, exportData: ExportData | null) => {
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const saveAndGetShareUrl = async (): Promise<string> => {
    if (!user || !exportData) {
      throw new Error("No user or export data available");
    }

    if (shareUrl) return shareUrl; // If already saved, return existing URL

    setIsSaving(true);
    try {
      const dataToSave = {
        ...exportData,
        userName: user.name
      };
      const newExportId = await handleApiSave(dataToSave);
      const newShareUrl = `${window.location.origin}/export/${newExportId}`;
      setShareUrl(newShareUrl);
      navigate(`/export/${newExportId}`, { replace: true });
      return newShareUrl;
    } catch (error) {
      console.error("Failed to save export:", error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  return { saveAndGetShareUrl, isSaving, shareUrl };
};

interface Message {
  sender: string;
  content: string;
}

export interface ExportData {
  character: string;
  userName: string;
  messages: Message[];
}

interface ChatExportProps {
  user?: AuthenticatedUser | undefined;
}

interface ChatMessageProps extends BoxProps {
  isUser?: boolean;
}

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#000000"
    },
    text: {
      primary: "#ffffff"
    }
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu, Cantarell, \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif"
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "black"
        }
      }
    }
  }
});

const ChatMessagesContainer = styled(Box)(({ theme }) => ({
  accentColor: "auto",
  alignContent: "normal",
  alignItems: "normal",
  alignSelf: "auto",
  backgroundAttachment: "scroll",
  backgroundColor: "rgba(0, 0, 0, 0)",
  backgroundImage: "none",
  backgroundPosition: "0% 0%",
  backgroundRepeat: "repeat",
  backgroundSize: "auto",
  blockSize: "calc(100vh - 200px)",
  boxSizing: "border-box",
  caretColor: "rgb(255, 255, 255)",
  color: "rgb(255, 255, 255)",
  columnGap: "normal",
  display: "block",
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  fontWeight: 400,
  height: "calc(100vh - 200px)",
  inlineSize: "100%",
  lineHeight: "normal",
  overflowX: "hidden",
  overflowY: "auto",
  paddingBlockStart: "16.84px",
  paddingInlineStart: "8.42px",
  paddingLeft: "8.42px",
  paddingTop: "16.84px",
  position: "relative",
  rowGap: "normal",
  tabSize: 8,
  textAlign: "center",
  width: "100%",
  zIndex: "auto"
}));

const ChatMessage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isUser"
})<ChatMessageProps>(({ theme, isUser }) => ({
  accentColor: "auto",
  alignItems: "flex-start",
  alignSelf: isUser ? "flex-end" : "flex-start",
  animation: "slideInFromBottom 0.5s forwards",
  backgroundColor: isUser ? "rgba(220, 247, 197, 0.9)" : "rgba(250, 250, 250, 0.953)",
  borderRadius: "12.63px",
  boxShadow: isUser ? "rgba(0, 0, 0, 0.1) 0px 4px 6px 0px" : "rgba(0, 0, 0, 0.075) 0px 0px 21.892px 0px",
  boxSizing: "border-box",
  caretColor: "rgb(51, 51, 51)",
  color: "rgb(51, 51, 51)",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter-Medium, sans-serif",
  fontSize: "15.156px",
  fontWeight: 400,
  margin: "0 0 16px 0",
  maxWidth: "70%",
  padding: "6.736px 8.541px",
  position: "relative",
  transform: "matrix(1, 0, 0, 1, 0, 0)"
}));

const ReactionButtons = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgba(250, 250, 250, 0.976)",
  borderRadius: "12px",
  bottom: "-20px",
  display: "flex",
  height: "18px",
  left: "10px",
  position: "absolute",
  width: "70.9375px"
}));

const ReactionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: "none",
  cursor: "pointer",
  display: "block",
  fontFamily: "Arial",
  fontSize: "14px",
  height: "18px",
  margin: "0 3px",
  opacity: 0.3,
  padding: "1px 6px",
  transform: "scale(1.1)",
  transition: "all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  width: "29.4688px"
}));


const ChatExport: React.FC<ChatExportProps> = ({ user }) => {
  const { exportId } = useParams<{ exportId?: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const chatContentRef = useRef<HTMLDivElement>(null);
  const [exportData, setExportData] = useState<ExportData | null>(null);
  const [loading, setLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchOrCreateExport = async () => {
      if (exportId) {
        try {
          const data = await handleApiFetch(exportId);
          setExportData(data);
          setShareUrl(`${window.location.origin}/export/${exportId}`);
        } catch (error) {
          console.error("Failed to fetch export data:", error);
          toast.error("Failed to load chat export");
        }
      } else if (location.state?.exportData) {
        setExportData(location.state.exportData);
      }
      setLoading(false);
    };

    fetchOrCreateExport();
  }, [exportId, location.state]);

  const saveAndGetShareUrl = async (): Promise<string> => {
    if (shareUrl) return shareUrl;

    if (!exportData) {
      throw new Error("No export data available");
    }

    setIsSaving(true);
    try {
      const dataToSave = {
        ...exportData,
        userName: user?.name || "Anonymous"
      };
      const newExportId = await handleApiSave(dataToSave);
      const newShareUrl = `${window.location.origin}/export/${newExportId}`;
      setShareUrl(newShareUrl);
      navigate(`/export/${newExportId}`, { replace: true });
      return newShareUrl;
    } catch (error) {
      console.error("Failed to save export:", error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const generateShareImage = async (): Promise<string | null> => {
    if (chatContentRef.current) {
      try {
        const originalStyle = chatContentRef.current.style.cssText;
        const originalHeight = chatContentRef.current.style.height;
        const originalOverflow = chatContentRef.current.style.overflow;

        chatContentRef.current.style.height = "auto";
        chatContentRef.current.style.overflow = "visible";

        const canvas = await html2canvas(chatContentRef.current, {
          backgroundColor: "#000000",
          scale: 2,
          useCORS: true,
          logging: false,
          windowWidth: chatContentRef.current.scrollWidth,
          windowHeight: chatContentRef.current.scrollHeight
        });

        chatContentRef.current.style.cssText = originalStyle;
        chatContentRef.current.style.height = originalHeight;
        chatContentRef.current.style.overflow = originalOverflow;

        return canvas.toDataURL("image/png");
      } catch (error) {
        console.error("Failed to generate share image:", error);
        toast.error("Failed to generate share image");
        return null;
      }
    }
    return null;
  };

  const handleTextDownload = () => {
    if (!exportData) return;
    const exportContent = `Chat between ${user?.name || "Anonymous"} and ${exportData.character}\n\n${exportData.messages
      .map((msg) => `${msg.sender === "user" ? (user?.name || "Anonymous") : exportData.character}: ${msg.content}`)
      .join("\n\n")}`;

    const blob = new Blob([exportContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `chat_with_${exportData.character.replace(/\s+/g, "_")}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleImageDownload = async () => {
    const imageDataUrl = await generateShareImage();
    if (imageDataUrl) {
      const link = document.createElement("a");
      link.href = imageDataUrl;
      link.download = `chat_with_${exportData?.character.replace(/\s+/g, "_")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Image downloaded successfully");
    }
  };

  const shareOnPlatform = async (platformUrl: (url: string, imageUrl: string) => string) => {
    try {
      const url = await saveAndGetShareUrl();
      const imageUrl = await generateShareImage();
      window.open(platformUrl(url, imageUrl || ""), "_blank");
    } catch (error) {
      console.error("Failed to share:", error);
      toast.error("Failed to share");
    }
  };

  const shareOnTwitter = async () => {
    try {
      const url = await saveAndGetShareUrl();
      const imageDataUrl = await generateShareImage();
      const text = `Check out my chat with ${exportData?.character}!`;

      if (imageDataUrl) {
        const link = document.createElement("a");
        link.href = imageDataUrl;
        link.download = `chat_with_${exportData?.character.replace(/\s+/g, "_")}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.info("Image downloaded. Please attach it manually when sharing on Twitter.");
      }

      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`,
        "_blank"
      );
    } catch (error) {
      console.error("Failed to share on Twitter:", error);
      toast.error("Failed to share on Twitter");
    }
  };

  const shareOnFacebook = () => {
    shareOnPlatform((url) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
  };

  const shareOnWhatsApp = () => {
    const text = `Check out my chat with ${exportData?.character}!`;
    shareOnPlatform((url) => `https://api.whatsapp.com/send?text=${encodeURIComponent(text + " " + url)}`);
  };

  const shareOnTelegram = () => {
    const text = `Check out my chat with ${exportData?.character}!`;
    shareOnPlatform((url) => `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
  };

  const copyLinkToClipboard = async () => {
    try {
      const url = await saveAndGetShareUrl();
      await navigator.clipboard.writeText(url);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy link:", error);
      toast.error("Failed to copy link");
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!exportData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Typography>No export data found. Please try reloading the page.</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Button color="inherit" onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
              Back
            </Button>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
              Chat Export
            </Typography>
            <Tooltip title="Download as Text">
              <IconButton color="inherit" onClick={handleTextDownload}>
                <GetApp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download as Image">
              <IconButton color="inherit" onClick={handleImageDownload}>
                <Image />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Link">
              <IconButton color="inherit" onClick={copyLinkToClipboard} disabled={isSaving}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Twitter">
              <IconButton color="inherit" onClick={shareOnTwitter} disabled={isSaving}>
                <Twitter />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Facebook">
              <IconButton color="inherit" onClick={shareOnFacebook} disabled={isSaving}>
                <Facebook />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on WhatsApp">
              <IconButton color="inherit" onClick={shareOnWhatsApp} disabled={isSaving}>
                <WhatsApp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Telegram">
              <IconButton color="inherit" onClick={shareOnTelegram} disabled={isSaving}>
                <Telegram />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <ChatMessagesContainer ref={chatContentRef}>
            <Typography variant="h5" gutterBottom align="center">
              Chat between {user?.name || "Anonymous"} and {exportData.character}
            </Typography>
            <List>
              {exportData.messages.map((msg, index) => (
                <ListItem key={index}
                          sx={{ display: "flex", justifyContent: msg.sender === "user" ? "flex-end" : "flex-start" }}>
                  <ChatMessage isUser={msg.sender === "user"}>
                    <Typography variant="body1">{msg.content}</Typography>
                    {msg.sender !== "user" && (
                      <ReactionButtons>
                        <ReactionButton size="small">👍</ReactionButton>
                        <ReactionButton size="small">👎</ReactionButton>
                      </ReactionButtons>
                    )}
                  </ChatMessage>
                </ListItem>
              ))}
            </List>
          </ChatMessagesContainer>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ChatExport;
