import React from "react";
import { Navigate } from "react-router-dom";
import { Session, SupabaseClient } from "@supabase/supabase-js";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  supabase: SupabaseClient;
  session: Session | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & { [key: string]: any }> = ({
                                                                                  component: Component,
                                                                                  supabase,
                                                                                  session,
                                                                                  ...rest
                                                                                }) => {
  return session ? <Component supabase={supabase} session={session} {...rest} /> : <Navigate to="/profile" />;
};

export default ProtectedRoute;